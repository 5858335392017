import { axiosCompanyRequest, axiosNtpRequestV1, authHeader, axiosBPMRequestV1 ,axiosGeneradoresRequest} from "../../configs/axios.config";

export class ServicesService {
  private static instance: ServicesService;

  private constructor() {}

  public static getInstance(): ServicesService {
    if (!ServicesService.instance) {
      ServicesService.instance = new ServicesService();
    }
    return ServicesService.instance;
  }

  async findAllServiceTypes(companyId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get<CreateDocumentSetResponse>(`/service-type/company/${companyId}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createDocumentSet(body: CreateDocumentSetReqBody) {
    try {
      const { data } = await axiosNtpRequestV1.post("/service-type/", body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteDocumentSet(body: DeleteDocumentSetReqBody) {
    try {
      await axiosNtpRequestV1.delete(`/service-type/${body.id}`, { headers: authHeader(), data: body });
    } catch (error) {
      throw error;
    }
  }

  async findServiceById(serviceId: string) {
    try {
      const { data } = await axiosCompanyRequest.get(`/services/${serviceId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  // async createService(body: CreateServiceReqBody) {
  //     const { data } = await axiosBPMRequestV1.post<boolean>("/bpm/services", body, { headers: authHeader() });
  //     if(!data) throw new Error("Hubo un error al dar de alta el servicio");
  // }

  async createService(body: CreateServiceReqBodyV2) {
    try {
      const { data } = await axiosNtpRequestV1.post(`/services`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateService(id: string, body: UpdateServiceReqBody) {
    try {
      const { data } = await axiosNtpRequestV1.put(`/services/${id}`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteService(serviceId: string) {
    try {
      const { data } = await axiosNtpRequestV1.delete(`/services/${serviceId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  // async getServicesByProvider(providerId:string) {
  //   try {
  //     const { data } = await axiosNtpRequestV1.get(`/services/providers/${providerId}`, { headers: authHeader() });
  //     return data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async getCollaboratorServices(getType: "providers" | "clients", companyId: string, collaboratorId: string) {
    const { data } = await axiosNtpRequestV1.get<GenericServicesResponse<GetServicesDataResponse>>(
      `/services/companies/${companyId}/${getType}/${collaboratorId}/lite`,
      {
        headers: authHeader(),
      }
    );
    return data;
  }

  async getPendingServices(getType: "providers" | "clients", companyId: string, collaboratorId: string) {
    const { data } = await axiosNtpRequestV1.get<GenericServicesResponse<PendingServiceResponse>>(
      `/invitations/${companyId}/${getType}/${collaboratorId}`,
      {
        headers: authHeader(),
      }
    );
    return data;
  }

  async answertServiceInvitation(processId: string, answer: boolean) {
    const { data } = await axiosBPMRequestV1.get(`/bpm/services/${processId}?invitationResponse=${answer}`, {
      headers: authHeader(),
    });
    return data;
  }

  //GET ​/api​/v1​/assortCfdiSua​/serviceId​/{serviceId}
  async getAssortCfdiSua(serviceId: string) {
    const { data } = await axiosNtpRequestV1.get<any>(`/assortCfdiSua/serviceId/${serviceId}`, {
      headers: authHeader(),
    });
    return data;
  }

  async getSuaNominaCfdi(serviceId: string) {
    const { data } = await axiosNtpRequestV1.get<any>(`/assortByService/serviceId/${serviceId}`, {
      headers: authHeader(),
    });
    return data;
  }

  //GET ​/api​/v1​/assortCfdiSua​/serviceId​/{serviceId}​/month​/{month}​/year​/{year}
  async getAssortCfdiSuaByMonth(serviceId: string, month: number, year: number) {
    const { data } = await axiosNtpRequestV1.get<any>(`/assortCfdiSua/serviceId/${serviceId}/month/${month}/year/${year}`, {
      headers: authHeader(),
    });
    return data;
  }

  //GET ​/api​/v1​/services​/buildServiceAsTree​/{serviceId}
  async buildServiceAsTree(serviceId: string) {
    const { data } = await axiosNtpRequestV1.get<any>(`/services/buildServiceAsTree/${serviceId}`, {
      headers: authHeader(),
    });
    return data;
  }



  //GENERADORES APIS

  async getCollaboratorGeneratorsServices(getType: "providers" | "clients", companyId: string, collaboratorId: string) {
    const { data } = await axiosGeneradoresRequest.get<GenericServicesResponse<GetServicesDataResponse>>(
      `/services/companies/${companyId}/${getType}/${collaboratorId}/lite`,
      {
        headers: authHeader(),
      }
    );
    return data;
  }

  async getPendingGeneratorsServices(getType: "providers" | "clients", companyId: string, collaboratorId: string) {
    const { data } = await axiosGeneradoresRequest.get<GenericServicesResponse<PendingServiceResponse>>(
      `/invitations/${companyId}/${getType}/${collaboratorId}`,
      {
        headers: authHeader(),
      }
    );
    return data;
  }


  async findAllGeneratorServiceTypes(companyId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get<CreateDocumentSetResponse>(`/service-type/company/${companyId}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createGeneratorDocumentSet(body: CreateDocumentSetReqBody) {
    try {
      const { data } = await axiosGeneradoresRequest.post("/service-type/", body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteGeneratorDocumentSet(body: DeleteDocumentSetReqBody) {
    try {
      await axiosGeneradoresRequest.delete(`/service-type/${body.id}`, { headers: authHeader(), data: body });
    } catch (error) {
      throw error;
    }
  }

  async findGeneratorServiceById(serviceId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get(`/services/${serviceId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }



  async createGeneratorService(body: CreateServiceReqBodyV2) {
    try {
      const { data } = await axiosGeneradoresRequest.post(`/services`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateGeneratorService(id: string, body: UpdateServiceReqBody) {
    try {
      const { data } = await axiosGeneradoresRequest.put(`/services/${id}`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteGeneratorService(serviceId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.delete(`/services/${serviceId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }


}//

