import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";
import * as Theme from "./styles/common";
import Routing from "./core/routes/routing.routes";
import { useEffect } from "react";
import useGlobalState from "./store/globalState";
import usePlanState from "./store/planState";
import GenericLoader from "./components/UI/GenericLoader";
import useUiState from "./store/uiState";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const {mainCompany,userInfo} = useGlobalState();
  const setPlan = usePlanState((s) => s.setPlan);
  
  const showGenericLoader = useUiState((s) => s.showGenericLoader);

  //handle plan
  useEffect(() => {
    if (mainCompany) {
      setPlan(userInfo,mainCompany.status as any, mainCompany.invitedAs ?? "provider", mainCompany.invited);
      //setPlan("PYME","client"); //only for dev test
    }
    //eslint-disable-next-line
  }, [mainCompany]);

  return (
    <ThemeProvider theme={Theme.kivitTheme}>
      <Routing />
      <>
        {/* app uicomponents */}
        {showGenericLoader && <GenericLoader />}
        <ToastContainer />
      </>
    </ThemeProvider>
  );
}

export default App;
